export const baseSubmitModalsMixin = {
  props: {
    confirmButtonTitle: {
      type: String,
      required: true,
    },
    confirmFunction: {
      type: Function,
      required: true,
    },
  },
  methods: {
    emitCloseErrorDetected() {
      this.$emit('close-error-detected');
    },
  },
};
