import { rapidAccessEvaluation as urlLayer } from '@/api/url_layer';
import Vue from 'vue';

export const rapidAccessEvaluation = {
  namespaced: true,
  state: {
    technicalEvaluationProposals: [],
    technicalEvaluationSelectedProposal: {},
  },
  mutations: {
    setTechnicalEvaluationProposals(state, proposals) {
      state.technicalEvaluationProposals = proposals;
    },
    setTechnicalEvaluationProposal(state, proposal) {
      const index = state.technicalEvaluationProposals.findIndex((el) => el._id.$oid === proposal._id.$oid);
      Vue.set(state.technicalEvaluationProposals, index, proposal);
    },
    setTechnicalEvaluationSelectedProposal(state, selectedProposal) {
      state.technicalEvaluationSelectedProposal = selectedProposal;
    },
  },
  actions: {
    notifyHandler({}, notification) {
      Vue.notify(notification);
    },
    async loadTechnicalEvaluationProposals({ commit, dispatch }, infrastructure) {
      let responseMessage;
      try {
        const response = await urlLayer.loadRapidAccessTechnicalEvaluationProposals(infrastructure);
        let title = 'Proposals loaded successfully';
        let { proposals } = response.data;
        if (response.status === 204) {
          title = 'No proposals to load';
          proposals = [];
        }
        commit('setTechnicalEvaluationProposals', proposals);
        responseMessage = { title, type: 'success' };
      } catch (error) {
        commit('setTechnicalEvaluationProposals', []);
        responseMessage = { title: error.response.data, type: 'error' };
      }
      dispatch('notifyHandler', responseMessage);
    },
    async saveSelectedProposalTechnicalEvaluation({ commit, getters, dispatch }, { infrastructure, evaluation }) {
      let responseMessage;
      try {
        const proposalId = getters.technicalEvaluationSelectedProposalId;
        const response = await urlLayer.saveRapidAccessProposalTechnicalEvaluation(
          proposalId,
          infrastructure,
          evaluation,
        );
        commit('setTechnicalEvaluationSelectedProposal', response.data.proposal);
        commit('setTechnicalEvaluationProposal', response.data.proposal);
        responseMessage = { title: 'Proposal evaluation saved successfully', type: 'success' };
      } catch (error) {
        const errorMessage = error.response.data.error ? error.response.data.error : error.response.data;
        responseMessage = {
          type: 'error',
          title: error.response.data === false ? 'Saving error!' : errorMessage,
        };
      }
      dispatch('notifyHandler', responseMessage);
    },
    async submitSelectedProposalTechnicalEvaluation({ commit, getters, dispatch }, infrastructure) {
      let responseMessage;
      try {
        const proposalId = getters.technicalEvaluationSelectedProposalId;
        const response = await urlLayer.submitRapidAccessProposalTechnicalEvaluation(proposalId, infrastructure);
        commit('setTechnicalEvaluationSelectedProposal', response.data.proposal);
        commit('setTechnicalEvaluationProposal', response.data.proposal);
        responseMessage = { title: 'Proposal evaluation submitted successfully', type: 'success' };
      } catch (error) {
        const errorMessage = error.response.data.error ? error.response.data.error : error.response.data;
        responseMessage = {
          type: 'error',
          title: error.response.data === false ? 'Saving error!' : errorMessage,
        };
      }
      dispatch('notifyHandler', responseMessage);
    },
  },
  getters: {
    technicalEvaluationSelectedProposalId: (state) => state.technicalEvaluationSelectedProposal._id.$oid || '',
    technicalEvaluationSelectedProposalGrade: (state) => state.technicalEvaluationSelectedProposal.inhouse_grade || {},
  },
};
