export const baseModalsMixin = {
  props: {
    modalTitle: {
      type: String,
      required: true,
    },
    modalName: {
      type: String,
      default: 'submitModal',
    },
    preventClose: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    hide() {
      if (!this.preventClose) {
        this.$refs[this.modalName].hide();
      } else {
        this.$emit('close-error-detected');
      }
    },
    show() {
      this.$refs[this.modalName].show();
    },
  },
};
