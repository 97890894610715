<template>
  <base-submit-modal :ref="modalName"
                     :confirm-button-title="confirmButtonTitle"
                     :confirm-function="confirmFunction"
                     :hide-header-close="preventClose"
                     :modal-title="modalTitle"
                     :no-close-on-backdrop="preventClose"
                     :no-close-on-esc="preventClose"
                     :prevent-close="preventClose"
                     cancel-button-variant="info"
                     confirm-button-variant="warning"
                     header-bg-variant="info"
                     size="xl"
                     v-bind="$attrs"
                     @close-error-detected="emitCloseErrorDetected">
    <slot/>
  </base-submit-modal>
</template>

<script>
  import BaseSubmitModal from '@/components/baseComponents/baseModals/BaseSubmitModal';
  import { baseModalsMixin } from '@/mixins/baseModals';
  import { baseSubmitModalsMixin } from '@/mixins/baseSubmitModals';

  export default {
    name: 'BaseSubmitInfoModal',
    components: { BaseSubmitModal },
    mixins: [baseModalsMixin, baseSubmitModalsMixin],
  };
</script>

<style scoped>

</style>
