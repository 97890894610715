<template>
  <b-card class="mb-2">
    <div v-if="!loading"
         class="call">
      <base-material-design-icon :icon="is_call_open ? 'lock_open' : 'lock'"
                                 class="vue-fa"/>
      <div class="call-info-note">
        <label id="call_info_note">
          {{ open_call_info }}
        </label>&nbsp;
        <label v-if="is_call_open">
          {{ display_date(this.current_calls[0].start_submission) }} -
          {{ display_date(this.current_calls[0].stop_submission) }}
        </label>
      </div>
    </div>
  </b-card>
</template>

<script>
  import { mapState } from 'vuex';
  import BaseMaterialDesignIcon from '@/components/baseComponents/baseIcons/BaseMaterialDesignIcon';

  export default {
    components: { BaseMaterialDesignIcon },
    data() {
      return { loading: true };
    },
    methods: {
      async load_data() {
        if (!this.current_calls?.length) {
          await this.$store.dispatch('calls/load_current_call');
        }
        this.loading = false;
      },
      display_date(date) {
        return new Date(date).toLocaleString();
      },
    },
    computed: {
      open_call_info() {
        if (this.current_calls === undefined || this.current_calls.length === 0) {
          return 'Calls are currently closed';
        }
        return 'Call is currently open';
      },
      is_call_open() {
        if (this.current_calls !== undefined) {
          return this.current_calls.length > 0;
        }
      },
      ...mapState('calls', { current_calls: 'current_call' }),
    },
    mounted() {
      this.load_data();
    },
  };
</script>

<style scoped>
  .card-body {
    padding: 0.3rem !important;
  }

  .call {
    font-size: 13px;
    width: 100%;
    border: 1px solid #edeff5;
    border-radius: 4px;
    line-height: 1em;
    overflow: hidden;
    min-height: 44px;
  }

  .vue-fa {
    color: teal;
    font-size: 30px;
    display: inline-block;
    width: 38px;
    float: left;
    padding: 8px 0 0 8px;
  }

  .call div {
    padding: 5px 5px 0 13px;
    border-left: 1px solid #edeff5;
    margin: 0 0 0 45px;
    min-height: 44px;
    overflow: hidden;
  }

  .call-info-note {
    display: flex;
    align-items: center;
  }

</style>
